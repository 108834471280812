/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(document).foundation(); // Foundation JavaScript
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // Smooth Scroll
        var topOffset = 0;
        $(".scroll-to, .scroll-to-li a").not('[href="#"]').not('[href="#0"]').click(function(t){if(location.pathname.replace(/^\//,"")===this.pathname.replace(/^\//,"")&&location.hostname===this.hostname){var e=$(this.hash);(e=e.length?e:$("[name="+this.hash.slice(1)+"]")).length&&(t.preventDefault(),$("html, body").animate({scrollTop:e.offset().top-topOffset},1e3,function(){var t=$(e);if(t.focus(),t.is(":focus"))return!1;t.attr("tabindex","-1"),t.focus()}))}});
       
        //var lightbox = $('.gallery-gallery a').simpleLightbox();

        /*$('.primary-navigation .menu-products, #product-menu-dropdown').on("mouseenter", function(e) {
          $("#product-menu-dropdown").delay(150).addClass('active');
          e.stopPropagation()
        });
        $('.primary-navigation .menu-products, #product-menu-dropdown').on("mouseleave", function(e) {
          $("#product-menu-dropdown").removeClass("active");
        });*/


        var timer;

        $('.primary-navigation .menu-products, #product-menu-dropdown').on("mouseover", function() {
          clearTimeout(timer);
          openSubmenu();
        }).on("mouseleave", function() {
          timer = setTimeout(
            closeSubmenu
          , 1000);
        });

        function openSubmenu() {
          $("#product-menu-dropdown").addClass('active');
        }
        function closeSubmenu() {
          $("#product-menu-dropdown").removeClass("active");
        }





        var lightbox = $('.gallery a').simpleLightbox({ /* options */ });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init
        
        $('.home-hero-images').slick({
          infinite: true,
          speed: 600,
          autoplaySpeed: 5000,
          fade: true,
          cssEase: 'linear',
          autoplay: true,
        });

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'single_suppliers': {
      init: function() {

        $('.dlm-downloads').filter(function(){
          return this.childNodes.length > 1
        }).addClass('threeColumns');

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
